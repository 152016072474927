export enum FEATURES {
  //Care Management page
  CARE_MANAGEMENT = 'care-management',

  //Create ticket page
  CREATE_TICKET = 'create-ticket',

  //Patient enrollment
  PATIENT_ENROLLMENT_TAB = 'patient-enrollment-folder',
  INVITE_PATIENTS = 'invite-patients',
  INVITE_NEW_PATIENT = 'invite-new-patient',
  ORAL_ONCOLYTICS_AND_SYMPTOM_ASSESSMENT_TAB = 'report-protocol-modal-oral-oncolytics-and-symptom-assessment-tab',
  AWAITING_ACTIVATION = 'awaiting-activation',

  //Patient list
  PATIENT_LIST = 'patient-list',
  REMOTE_MONITORING_STATUS_FILTER = 'pl-filter-remote-monitoring-status',

  //Patient page
  PATIENT_PAGE = 'patient-page',
  OPT_OUT_OF_REMOTE_MONITORING_OPTION = 'edit-modal-opt-out-of-remote-monitoring',
  PATIENT_ENROLLMENT_STATUS = 'edit-modal-patient-enrollment-status',
  CALLBACK_CONTACTS = 'edit-modal-callback-contacts',
  REPORT_PROTOCOL = 'edit-modal-report-protocol',
  OPEN_ITEMS_TAB = 'open-items-tab',
  PATIENT_REPORTED_OUTCOMES_CHART = 'open-items-patient-reported-outcomes-chart',
  DISTRESS_THERMOMETER_ROW = 'open-items-patient-reported-outcomes-chart-distress-thermometer-row',
  RESOLVED_TICKETS_TAB = 'resolved-tickets-tab',
  CALLS_TAB = 'calls-tab',
  EPISODE_AND_TASKS_TAB = 'episodes-and-tasks-tab',

  //Actions menu
  ACTIONS_LOG_CALLS = 'actions-log-call',
  ACTIONS_LOG_CALLS_PATHWAYS = 'actions-log-call-pathways',
  ACTIONS_INVITE_TO_SUBMIT_REPORTS = 'actions-invite-to-submit-reports',
  ACTIONS_HYBRID_PROTOCOL = 'actions-hybrid-protocol',
  ACTIONS_REQUEST_REPORTS = 'actions-request-report',
  ACTIONS_CREATE_TICKET = 'actions-create-ticket',
  ACTIONS_CREATE_TASK = 'actions-create-task',

  //WQ sections
  WORK_QUEUE_PAGE = 'work-queue',
  URGENT_PATIENT_REPORTS = 'urgent-patient-reports',
  TICKETS_AND_CALLBACK_REQUESTS = 'tickets-and-callback-requests',
  TASKS_DUE = 'tasks-due',
  OTHER_PATIENTS_REPORTS = 'other-patient-reports',
  OVERDUE_REPORTS = 'overdue-patient-reports',

  //WQ Filters
  SEARCH_BY_NAME_OR_MRN_FILTER = 'wq-filter-search-by-name-or-mrn',
  PATIENT_TAGS_FILTER = 'wq-filter-patient-tags',
  PROVIDERS_FILTER = 'wq-filter-providers',
  LOCATIONS_FILTER = 'wq-filter-locations',
  ASSIGNEES_FILTER = 'wq-filter-assignees',
  SEARCH_BY_TASK_FILTER = 'wq-filter-search-by-task',
  TASK_BY_LAST_NAME_FILTER = 'wq-filter-task-by-last-name',
  EPISODES_FILTER = 'wq-filter-episodes',
  TASK_ROLE_FILTER = 'wq-filter-task-role',
  TASK_STATUS_FILTER = 'wq-filter-task-status',
  TASK_OWNER_FILTER = 'wq-filter-task-owner',

  //CM (enrollment) Filters
  ELIGIBILITY_SCORE_FILTER = 'cm-enrollment-filter-eligibility-score',
  ACTIVE_TX_FILTER = 'cm-enrollment-filter-active-tx',

  CM_SUGGESTED_STATUSES = 'cm-suggested-statuses',

  //WQ item types general filters
  ORAL_ONCOLYTICS_ASSESSMENT = 'wq-filter-item-types-oral-oncolytics-assessment',
  SYMPTOM_ASSESSMENT = 'wq-filter-item-types-symptom-assessment',
  CALLBACK_REQUESTS = 'wq-filter-item-types-callback-requests',
  EPISODE_TASKS = 'wq-filter-item-types-episode-tasks',
  NON_EPISODE_TASKS = 'wq-filter-item-types-non-episode-tasks',
  OVERDUE_SYMPTOM_ASSESSMENT = 'wq-filter-item-types-overdue-symptom-assessment',
  OVERDUE_ORAL_ONCOLYTICS_ASSESSMENT = 'wq-filter-item-types-overdue-oral-oncolytics-assessment',
  TICKET_TYPES_AND_SUB_TYPES = 'wq-filter-item-types-ticket-types-and-sub-types',

  //My account
  MY_ACCOUNT = 'user-my-account',
  TRIAGE_RULES = 'user-triage-rules',

  //Practice manager
  PRACTICE_MANAGER = 'user-practice-manager',
  PM_USERS = 'pm-users',
  PM_TICKETS_TYPES = 'pm-ticket-types',
  PM_PATIENT_TAGS = 'pm-patient-tags',
  PM_PROVIDERS = 'pm-providers',
  PM_LOCATIONS = 'pm-locations',
  PM_EPISODES = 'pm-episodes-view',
  PM_VIEW_AND_EDIT_EPISODES = 'pm-episodes-edit-episodes',
  PM_CREATE_EPISODES = 'pm-episodes-create-episodes',

  //Thought spot page
  PRACTICE_ANALYTICS_PAGE = 'ip-analytics',
  IP_PRACTICE_HEALTH = 'ip-practice-health',
  IP_TICKETS = 'ip-tickets',
  IP_CARE_TIMER_AND_CALLS = 'ip-care-timer-and-calls',
  IP_PATHWAYS_ANALYSIS = 'ip-pathway-analysis',
  IP_EPRO_ENROLLMENT = 'ip-epro-enrollment',
  IP_CARE_MANAGEMENT = 'ip-care-management',
  ANALYTICS_DRILL_DOWN_ACTION = 'analytics-drill-down-action',

  //Patient Requests
  PATIENT_REQUESTS = 'patient-requests',

  PATIENT_PAGE_FILTERS = 'patient-page-filters',

  //Notifications
  NOTIFICATIONS = 'system-changes-notifications',

  //Mentions
  MENTIONS = 'mentions-input',

  //Patient Led Enrollment
  PATIENT_LED_ENROLLMENT = 'patient-led-enrollment',

  //Auto Protocol
  AUTO_PATIENT_ENROLLMENT = 'auto-patient-enrollment',

  // Oral Auto Protocol
  ORAL_AUTO_PROTOCOL = 'oral-auto-protocol',

  //Group by patient
  GROUP_TASKS_BY_PATIENT = 'group-tasks-by-patient',

  CALL_LOGGER_SMART_SUMMARY = 'call-logger-smart-summary',

  // EMR Links
  EMR_LINK_PATIENT_SUMMARY = 'emr-link-patient-summary',

  LIGHTWEIGHT_PATHWAYS = 'lightweight-pathways',

  // Pendo
  PENDO = 'pendo',

  DRUG_SPECIFIC = 'drug-specific',
  REGIONS = 'regions'
}

export const ANALYTICS_FEATURES = [
  FEATURES.PRACTICE_ANALYTICS_PAGE,
  FEATURES.IP_PRACTICE_HEALTH,
  FEATURES.IP_TICKETS,
  FEATURES.IP_CARE_TIMER_AND_CALLS,
  FEATURES.IP_PATHWAYS_ANALYSIS,
  FEATURES.IP_EPRO_ENROLLMENT,
  FEATURES.IP_CARE_MANAGEMENT
];
